export const parseQuery = (url: Location) => {
  var search = url.search.substring(1);
  if (!search) return '';
  const parsed = JSON.parse(
    '{"' +
      decodeURI(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"') +
      '"}'
  );
  return parsed;
};
